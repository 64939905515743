export default {
  Preorder: {
    Pellet: 'ペレット',
    PreorderID: '予約商品ID',
    PreorderCode: '商品コード',
    Weight: '重量',
    PurchaseRange: '上限',
    PriceJPY: '価格(JPY)',
    PriceUSD: '価格(USD)',
    Period: '予約期間',
  },
  Auction: {
    Pellet: 'ペレット',
    AuctionID: '入札商品ID',
    Code: '商品コード',
    Weight: '重量',
    MinAmount: '最低金額',
    MinJPY: '最低入札金額(JPY)',
    MinUSD: '最低入札金額(USD)',
    Period: '入札期間',
  },

  Quotation: {
    Quotation: '見積書',
    No: 'No.',
    Date: 'Date',
    QuotationDate: '見積書日',
    Description: '下記の通り、御見積申し上げます。',
    FinalAmount: '見積金額',
    BidAmount: '入札金額',
    BidUnit: '式',
    Subtotal: '小計',
    Discount: '値引',
    Total: '合計',
    ItemInfo: '商品情報',
    Qty: '数量',
    Unit: '単位',
    Price: '単価',
    Weight: '重量',
    Amount: '金額',
    Note: '備考',
    Expiration: '有効期限',
  },
  Invoice: {
    Invoice: '請求書',
    Code: 'No.',
    Date: '請求日',
    SoldTo: '販売先',
    Address: 'アドレス',
    TAX: 'TAX',
    Tel: '電話番号',
    Email: 'メールアドレス',
    ATTN: 'ご担当者様',
    Payment: '支払い方法',
    ItemDesc: '商品説明',
    Weight: '重量',
    Price: '単価',
    Amount: '金額',
    Total: '総額',
    CostAndFreight: 'C&F SHEKOU',
    CountryName: '国名',
    JpBankName: '日本銀行名',
    CardBank: 'カート番号',
    BankAddress: '銀行アドレス',
    USDAccount: 'USDアカウント',
    AccountName: 'アカウント名',
    AccountAddress: 'アドレス',
    AccountTel: '電話番号',
  },
  Contract: {
    Contract: '契約書',
    Code: 'No.',
    Date: '契約日',
    SoldTo: '販売先',
    Address: 'アドレス',
    TAX: 'TAX',
    Tel: '電話番号',
    Email: 'メールアドレス',
    ATTN: 'ご担当者様',
    Payment: '支払い方法',
    ItemDesc: '商品説明',
    Weight: '重量',
    Price: '単価',
    Amount: '金額',
    Total: '総額',
    CostAndFreight: 'C&F SHEKOU',
    CountryName: '国名',
    JpBankName: '日本銀行名',
    CardBank: 'カート番号',
    BankAddress: '銀行アドレス',
    USDAccount: 'USDアカウント',
    AccountName: 'アカウント名',
    AccountAddress: 'アドレス',
    AccountTel: '電話番号',
  },
  GreenEarth: {
    CompanyName: '株式会社グリーン・アース',
    Address: '茨城県坂東市猫実1679番１',
  },
}
